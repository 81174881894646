import { RecruitCommonTextMeta } from "@/ts/RecruitCommonTextMeta";
import { RecruitTextMeta } from "@/ts/RecruitTextmeta";
var RecruitView = /** @class */ (function () {
    function RecruitView() {
        this.id = 0;
        this.lang = 'ko';
        this.code_metas = {
            location: [],
            task: [],
            work_type: [],
            work_duration: [],
            work_date: [],
            work_paytype: [],
            welfare_insurance: [],
            welfare_meals: [],
            welfare_trans: [],
            welfare_lodging: [],
            welfare_bonus: [],
            welfare_vacation: [],
            con_docu: [],
            con_visa: [],
            con_language: [],
            con_sex: [],
            con_age: [],
            con_edu: [],
            info_country: [],
            special_visasp: [],
        };
        this.common_text_metas = new RecruitCommonTextMeta();
        this.text_metas = new RecruitTextMeta();
        this.file_metas = {
            company_photos: []
        };
    }
    return RecruitView;
}());
export { RecruitView };
