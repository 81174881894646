import {startSpinner} from "@/ts/modules/startSpinner.ts";

const API_HOST = process.env.MIX_API_HOST

//slick slider
$('#mainSlider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: true,
    speed: 800,
    prevArrow: $('#main_prev'),
    nextArrow: $('#main_next'),
    autoplaySpeed: 3000
});

$(document).ready(function () {
    // 이메일 유효성 검사(유효성이 맞지 않으면 아예 submit 버튼 비활성화)
    var emailInput = $('.email_form input');
    var submitButton = $('button[type="submit"]');

    emailInput.on('input', function () {
        var emailValue = emailInput.val();

        // 이메일 주소의 유효성 검사 (클라이언트 측)
        var emailRegex = /^[a-zA-Z0-9_\-가-힣一-龠\u0100-\u024F\u1E00-\u1EFF\.]+@([\w-]+\.)+[\w-]{2,}$/;
        var isValidEmail = emailRegex.test(emailValue);

        submitButton.prop('disabled', !isValidEmail);

        // 부트스트랩에서 제공하는 유효성 상태 클래스 활용
        if (!isValidEmail) {
            emailInput.addClass('is-invalid');
        } else {
            emailInput.removeClass('is-invalid');
        }
    });
});

//이메일 
$('.email_form').submit(function(event){
    event.preventDefault();
    let data = $('.email_form').serializeJSON();

    const {spinnerContainer, stopSpinner} = startSpinner();         

    axios.post(API_HOST + '/mail/apply-subscription', data)
    .then((response) => {
        Swal.fire({
            html: successMessage,
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
            backdrop: false
        })

        $('.email_form .form-control').val('');
    })
    .catch((error) => {
        Swal.fire({
            text: "error",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: confirmMessage,
            heightAuto: false,
            customClass: {
                confirmButton: "btn btn-primary",
            },
        });
        console.log(error);
    })	
    .finally(() => {
        stopSpinner(spinnerContainer);
    });
})