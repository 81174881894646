var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, vModelText as _vModelText } from "vue";
var _hoisted_1 = { class: "d-flex align-items-center" };
var _hoisted_2 = ["innerHTML"];
var _hoisted_3 = { class: "rule" };
var _hoisted_4 = {
    key: 1,
    class: "files upload_list_group d-flex align-items-center flex-wrap"
};
var _hoisted_5 = {
    key: 0,
    class: "upload_list d-flex align-items-center"
};
var _hoisted_6 = { class: "d-flex align-items-center" };
var _hoisted_7 = { class: "d-flex align-items-center" };
var _hoisted_8 = {
    key: 0,
    src: "/images/icons/icon_pdf.png",
    alt: ""
};
var _hoisted_9 = {
    key: 1,
    src: "/images/icons/icon_image.png",
    alt: ""
};
var _hoisted_10 = { class: "filename" };
var _hoisted_11 = ["onClick"];
var _hoisted_12 = ["name", "onUpdate:modelValue"];
var _hoisted_13 = ["name", "onUpdate:modelValue"];
var _hoisted_14 = ["name", "onUpdate:modelValue"];
var _hoisted_15 = ["name", "onUpdate:modelValue"];
import Swal from "sweetalert2";
import { reactive, watch, ref, onMounted } from 'vue';
import { useDropzone } from 'vue3-dropzone';
import axios from "axios";
var __default__ = {
    name: 'file-upload-component',
};
export default /*@__PURE__*/ _defineComponent(__assign(__assign({}, __default__), { props: {
        url: {
            required: true,
            type: String,
        },
        maxFiles: {
            required: true,
            type: Number,
        },
        files: {
            requird: false,
            default: [],
            type: (Array)
        },
        editable: {
            required: false,
            type: Boolean,
            default: true
        },
        locales: {
            required: true,
            type: Object
        },
        errText: {
            required: true,
            type: String
        },
        confirmText: {
            required: true,
            type: String
        },
    }, emits: ['changed'], setup: function (__props, _a) {
        var __emit = _a.emit;
        var emit = __emit;
        var props = __props;
        var url = props.url;
        var maxFileCount = props.maxFiles;
        var state = reactive({
            files: ref([]),
        });
        var files = props.files;
        onMounted(function () {
            console.log("mounted");
            console.log("files.length", files.length);
        });
        var didSyncedPhotos = false; // 최초 1번만...
        watch(files, function () {
            if (files.length > 0 && !didSyncedPhotos) {
                files.forEach(function (photo) {
                    state.files.push(photo);
                });
                didSyncedPhotos = true;
            }
        });
        var _b = useDropzone({
            onDrop: onDrop,
        }), getRootProps = _b.getRootProps, getInputProps = _b.getInputProps, isDragActive = _b.isDragActive, rest = __rest(_b, ["getRootProps", "getInputProps", "isDragActive"]);
        watch(state, function () {
            console.log('state', state);
        });
        watch(isDragActive, function () {
            console.log('isDragActive', isDragActive.value, rest);
        });
        function onDrop(acceptFiles, rejectReasons) {
            console.log(acceptFiles, rejectReasons);
            if (acceptFiles.length + state.files.filter(function (file) { return file.status != 'delete'; }).length > maxFileCount) {
                return Swal.fire({
                    text: props.errText,
                    icon: "error",
                    confirmButtonText: props.confirmText,
                    heightAuto: false,
                });
            }
            var formData = new FormData(); // pass data as a form
            for (var x = 0; x < acceptFiles.length; x++) {
                // append files as array to the form, feel free to change the array name
                formData.append("files[]", acceptFiles[x]);
            }
            // post the formData to your backend where storage is processed. In the backend, you will need to loop through the array and save each file through the loop.
            axios.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(function (response) {
                console.info(response.data);
                response.data.data.forEach(function (element) {
                    element.status = "create";
                    state.files.push(element);
                });
                emit('changed', state.files);
            })
                .catch(function (err) {
                console.error(err, err.response.data);
                var errMessage = err.response.data ? err.response.data.message : err.response.statusText;
                Swal.fire({
                    text: errMessage,
                    icon: "error",
                    confirmButtonText: props.confirmText,
                    heightAuto: false,
                });
            });
        }
        function handleClickDeleteFile(index) {
            if (state.files[index].status == 'create') {
                state.files.splice(index, 1);
            }
            else {
                state.files[index].status = 'delete';
            }
            emit('changed', state.files);
        }
        function getValidCount() {
            return state.files.filter(function (file) { return file.status != 'delete'; }).length;
        }
        return function (_ctx, _cache) {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createElementVNode("div", null, [
                    (getValidCount() < __props.maxFiles && __props.editable)
                        ? (_openBlock(), _createElementBlock("div", _mergeProps({ key: 0 }, _unref(getRootProps)(), { class: "dropzone d-flex align-items-center justify-content-center" }), [
                            _createElementVNode("input", _mergeProps(_unref(getInputProps)(), {
                                name: "resume_attachments",
                                type: "file",
                                accept: "image/*,application/pdf"
                            }), null, 16),
                            _createElementVNode("div", _hoisted_1, [
                                _cache[0] || (_cache[0] = _createElementVNode("img", {
                                    src: "/images/icons/imgdrag.svg",
                                    alt: ""
                                }, null, -1)),
                                _createElementVNode("p", {
                                    innerHTML: __props.locales.mypage_cv_s7_attach_upload_ph
                                }, null, 8, _hoisted_2)
                            ])
                        ], 16))
                        : _createCommentVNode("", true),
                    _createElementVNode("span", _hoisted_3, _toDisplayString(__props.locales.mypage_cv_s7_attach_desc3_rule), 1),
                    (getValidCount() > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.files, function (file, index) {
                                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                                    (file.status != 'delete')
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                            _createElementVNode("div", _hoisted_6, [
                                                _createElementVNode("div", _hoisted_7, [
                                                    (file.file_type == 'pdf')
                                                        ? (_openBlock(), _createElementBlock("img", _hoisted_8))
                                                        : (_openBlock(), _createElementBlock("img", _hoisted_9)),
                                                    _createElementVNode("span", _hoisted_10, _toDisplayString(file.name), 1)
                                                ]),
                                                _withDirectives(_createElementVNode("button", {
                                                    type: "button",
                                                    class: "x_btn",
                                                    onClick: function ($event) { return (handleClickDeleteFile(index)); }
                                                }, _cache[1] || (_cache[1] = [
                                                    _createElementVNode("i", { class: "bi bi-x" }, null, -1)
                                                ]), 8, _hoisted_11), [
                                                    [_vShow, __props.editable]
                                                ])
                                            ])
                                        ]))
                                        : _createCommentVNode("", true)
                                ], 64));
                            }), 128))
                        ]))
                        : _createCommentVNode("", true)
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.files, function (file, seq) {
                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                        _withDirectives(_createElementVNode("input", {
                            type: "hidden",
                            name: "resume_attachments[".concat(seq, "][path]"),
                            "onUpdate:modelValue": function ($event) { return ((file.path) = $event); }
                        }, null, 8, _hoisted_12), [
                            [_vModelText, file.path]
                        ]),
                        _withDirectives(_createElementVNode("input", {
                            type: "hidden",
                            name: "resume_attachments[".concat(seq, "][name]"),
                            "onUpdate:modelValue": function ($event) { return ((file.name) = $event); }
                        }, null, 8, _hoisted_13), [
                            [_vModelText, file.name]
                        ]),
                        _withDirectives(_createElementVNode("input", {
                            type: "hidden",
                            name: "resume_attachments[".concat(seq, "][status]"),
                            "onUpdate:modelValue": function ($event) { return ((file.status) = $event); }
                        }, null, 8, _hoisted_14), [
                            [_vModelText, file.status]
                        ]),
                        _withDirectives(_createElementVNode("input", {
                            type: "hidden",
                            name: "resume_attachments[".concat(seq, "][id]"),
                            "onUpdate:modelValue": function ($event) { return ((file.id) = $event); }
                        }, null, 8, _hoisted_15), [
                            [_vModelText, file.id]
                        ])
                    ], 64));
                }), 256))
            ], 64));
        };
    } }));
