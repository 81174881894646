import ApiService from "@/ts/core/services/ApiService";
var UploadAdapter = /** @class */ (function () {
    function UploadAdapter(loader) {
        this.loader = loader;
        console.log('loader', loader);
    }
    // Starts the upload process.
    UploadAdapter.prototype.upload = function () {
        return this.loader.file
            .then(function (file) { return new Promise(function (resolve, reject) {
            var data = new FormData();
            if (file) {
                data.append('image', file);
            }
            ApiService.post("".concat(process.env.MIX_API_HOST, "/editor/image"), data)
                .then(function (_a) {
                var data = _a.data;
                console.log("data", data);
                resolve({
                    default: data.data.url
                });
            })
                .catch(function (_a) {
                var response = _a.response;
                console.log("response", response);
                var message = 'ServerError';
                if (response) {
                    message = response.data.message;
                }
                return reject(response && response.data ? response.data.message : message);
            });
        }); });
    };
    return UploadAdapter;
}());
export default UploadAdapter;
