export var ServiceLanguages;
(function (ServiceLanguages) {
    ServiceLanguages["KOREAN"] = "ko";
    ServiceLanguages["ENGLISH"] = "en";
    ServiceLanguages["VIETNAM"] = "vi";
    ServiceLanguages["MONGOLIAN"] = "mn";
    ServiceLanguages["UZBEK"] = "uz";
    ServiceLanguages["INDONESIAN"] = "id";
    ServiceLanguages["THAI"] = "th";
    ServiceLanguages["NEPALI"] = "ne";
    ServiceLanguages["JAPANESE"] = "ja";
    ServiceLanguages["CHINESE"] = "zh";
})(ServiceLanguages || (ServiceLanguages = {}));
