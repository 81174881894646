import axios from 'axios';
import { reactive } from 'vue';
var presetCodes = reactive({
    location: [],
    task: [],
    work_type: [],
    work_duration: [],
    work_date: [],
    work_paytype: [],
    welfare_insurance: [],
    welfare_meals: [],
    welfare_trans: [],
    welfare_lodging: [],
    welfare_bonus: [],
    welfare_vacation: [],
    con_docu: [],
    con_visa: [],
    con_language: [],
    con_sex: [],
    con_age: [],
    con_edu: [],
    info_country: [],
    special_visasp: [],
});
export function loadPreset() {
    axios.get(process.env.MIX_API_HOST + '/service-code/preset')
        .then(function (response) {
        for (var group in response.data.data) {
            presetCodes[group] = response.data.data[group];
        }
    });
}
export default { presetCodes: presetCodes, loadPreset: loadPreset };
