var UserFile = /** @class */ (function () {
    function UserFile() {
        this.id = 0;
        this.key = '';
        this.filename = '';
        this.url = '';
        this.path = '';
        this.name = '';
        this.status = 'create';
        this.file_type = 'image';
    }
    return UserFile;
}());
export { UserFile };
